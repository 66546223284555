
/* Publications.css */

.publications-section {
  padding: 60px 0;
  background-color: #ffffff;
}

.project-heading {
  font-size: 2.5rem;
  color: #2c3e50;
  margin-bottom: 1.5rem;
  text-align: center;
}

.section-title {
  font-size: 1.5rem;
  color: #34495e;
  margin-top: 2rem;
  margin-bottom: 1rem;
  text-decoration: underline;
}

.publication-item {
  font-size: 1rem;
  line-height: 1.5;
  color: #2c3e50;
}

.publication-item a {
  color: #3498db;
  text-decoration: none;
}

.publication-item a:hover {
  text-decoration: underline;
}
