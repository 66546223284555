.experience-section {
    padding: 60px 0;
    background-color: #f9f9f9; /* Light grey background for the entire section */
  }
  
  .section-title {
    font-size: 2rem;
    color: #343a40; /* Dark grey for the title */
    margin-bottom: 2rem; /* Space below the section title */
  }
  
  .experience-card {
    margin-bottom: 1.5rem; /* Space between cards */
    border: none;
    border-radius: 0.5rem;
    box-shadow: 0 4px 8px rgba(0, 0, 0, 0.1); /* Subtle shadow for depth */
    overflow: hidden; /* Ensures the child elements do not break the rounded corners */
  }
  
  .experience-card .card-header {
    background-color: #000000; /* Bootstrap's primary color */
    color: #fff;
    padding: 0.75rem 1.25rem;
    display: flex;
    align-items: center;
    font-size: 1.2rem;
  }
  
  .experience-card .card-header .icon {
    margin-right: 10px;
  }
  
  .experience-card .card-body {
    padding: 1.25rem;
    background-color: #fff; /* White background for card's body */
  }
  
  .experience-card .card-title {
    font-weight: 600; /* Slightly heavier font for title */
    color: #333; /* Dark text for better readability */
    margin-bottom: 0.5rem;
  }
  
  .experience-card .card-subtitle {
    font-size: 0.9rem;
    color: #6c757d; /* Bootstrap's secondary text color */
    margin-bottom: 1rem;
  }
  
  .experience-card .card-text {
    font-size: 0.9rem;
    line-height: 1.6;
    color: #495057; /* A slightly lighter shade for the text */
  }
  
  @media (max-width: 767.98px) {
    .experience-card {
      margin-left: 15px;
      margin-right: 15px;
    }
  }
  
  /* Add any additional media queries or CSS rules as needed */
  
  